import React, { useState ,useEffect} from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWebProperties } from '../../webProperties';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import RootLoader from '../../Loader/RootLoader';

import Layout from '../../../Optimisation/MetaData/Layout/Layout';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
export default function ReleaseNote() {
  const { APP_NAME, IMG_SRC } = useWebProperties();
  const webProperties = useSelector(state => state.landingReducer.webProperties)
  const properties = useSelector(state => state.landingReducer.properties)
 // const [searchQuery, setSearchQuery] = useState('');
 const [loading, setLoading] = useState(false);
 const releaseNotes = [
  {
    id: 138,
    version: "4.7.3",
    date: "11-13-2024",
    highlights: [
      "Feature: Added calendar repeat event creation functionality",  
      "Bug Fixes: Added the usercosting and projectcosting at all tasker",
      "Bug Fixes: Cleared the previously searched task ID when switching between the Timeline and Support Board in the Archive.",
      "Bug Fixes: Fixed the Search functionality issue in Release Notes.",
      "Bug Fixes: Fixed the alignment issue in top navbar when project title is too large.",
    ]
  },
    {
      id: 138,
      version: "4.7.3",
      date: "11-13-2024",
      highlights: [
        "Feature:The admin reports have been improved by relocating the User Reports, Project Reports, and Usage Reports to the Reports tab. Additionally, the Project Costing and User Costing reports have been moved to the Revenue and Billing section.",
        "Bug Fixes: When a task is added to a project without an active timeline, a default timeline is automatically created, and the task is then assigned to this default timeline."    
      ]
    },
    {
      id: 138,
      version: "4.7.3",
      date: "11-12-2024",
      highlights: [
        "Bug Fixes: Fixed Scroll bar issue(hidden nav Items) on Side Nav in Admin and User",
      ],
    },
    {
      id: 137,
      version: "4.7.2",
      date: "11-08-2024",
      highlights: [
        "Feature: When a user sends a message from WeTasker web or WeTasker, Rallysquare apps user receive any notifications.",
        "Feature:Added event acknowledgment functionality",
        "Bug Fixes: On MyTasker P:0 tickets showing on bottom",
        "Bug Fixes: Hiding the all team members when user role is LAC on Add Task and only can assign to him/her self",
        "Bug Fixes: On LAC, user can can only see the communicated users on 1-1 chat",
        "Bug Fixes: Display the involved projects users list on 1-1 chat",
        "Bug Fixes: Modified the Timeline Report in the 'All Reports' tab in the admin panel by grouping the users and displaying the task information when a user clicks on the username column.",
        "Bug Fixes: Fixed the Error message while upload files and message at a time in Chats.",
        "Bug Fixes:  Web-Can't see all my active projects from sideNav.",
        "Bug Fixes:  Wherever we are giving an option to choose, if there is only 1 option, pick it up by default.",    
      ]
    },
    {
      id: 136,
      version: "4.7.1",
      date: "10-30-2024",
      highlights: [
        "Feature: Added task breakdown and user information to the UTS report for each user and module.",
        "Feature: In group, task, and sub-task chats, the person who sent the message can see who has viewed the chat.",
        "Bug Fixes: Show User reports, Project reports and All Reports based on account level",
        "Bug Fixes: In All Reports, Projects list displayed by account level for account admins",
        "Bug Fixes: In User Activity Logs, logs are displayed by account level for account admins",
        "Bug Fixes: fixed task compete/pause  reason in the input feild at Mytasker.",
        "Bug Fixes: Added the validation for Phone number when we are adding team member in project.",
        "Bug Fixes: The Agilenexapp project has been removed from the side navigation for LAC users.",
        "Bug Fixes: Fixed the issue where modifying a task in My Tasker (Assigned to Others) was not auto-refreshing the changes.",
        "Bug Fixes: we can start sub task from modules priority wise"
      ]
    },
    {
      id: 135,
      version: "4.7.0",
      date: "10-23-2024",
      highlights: [
        "Feature: Added UTS report to All Reports in the admin portal.",
        "Bug Fixes: Updated the labels of Project Reports and Project Time Report to User Reports and Project Reports in the admin side navigation.",
        "Bug Fixes: Hide the module name, timeline name, target time, and subtask progress from task details for contributors with limited access.",
        "Bug Fixes: Resolved the alignment issue in the actions section of the meeting tabs in MyTasker.",
        "Bug Fixes: Fixed the issue where search results in the 'Assigned to Others' section of MyTasker persisted after switching to another tab and returning.",
      ]
    },
    {
      id: 134,
      version: "4.6.9",
      date: "10-17-2024",
      highlights: [
        "Feature: Adding task into default sprint when added from both Web and App.",
        "Feature: Disabled AgileNexApp project to user Support ticket from outside.",
        "Feature: My Tasker filter/sort options by priority, taskId and user(assigned by/assigned to)",
        "Feature: Added activity log information to MyTasker, similar to the Planned Board and Support Board.",
        "Feature: Added user activity logs for user registration, inside support ticket, Project Management(Create Project, Archive Project, Reopen Project)",
        "Feature: Added Pause/Inprogress button for MyTasker Assigned to Me",
        "Bug Fixes: Fixed Sidenav, Customized Scrollbar & UI enhancements.",
        "Bug Fixes: Hide/unhide password option added at reset password screen.",
        "Bug Fixes: The automatic reply feature for support tickets has been removed.",
        "Bug Fixes: Fixed the team screen does not highlight when a team member is selected.",
        "Bug Fixes: Fixed the project name is misaligned when it is too long and added the condition limit for project.",
        "Bug Fixes: Updated the password suggestion text during account creation.",
      ]
    },
    {
      id: 133,
      version: "4.6.8",
      date: "10-10-2024",
      highlights: [
        "Feature: Added activity logs for navigation activities in the admin portal.",
        "Feature: Added activity logs for calendar activities for both the outside and inside calendars.",
        "Feature: In Project Costing, project details are added along with a summary of the table.",
        "Feature: Notifications stop after logging out from the application on both web and mobile.",
        "Feature: Disabled main group task at Assigned to Me, Planned board, Backlog Current sprints.",
        "Bug Fixes: The 'View Workflow' option is disabled for template tasks if it's not workflow.",
        "Bug Fixes: Add and modify personal tasks without updating only the title in Personal Tasker.",
        "Bug Fixes: After completing the task in MyTasker, the completion reason page remains visible and takes too much time to disappear.",
        "Bug Fixes: LAC customers cannot access others' contacts in a one-to-one chat until a message has been exchanged.",
        "Bug Fixes: Users cannot create subtasks if they are not part of the project from MyTasker.",
        "Bug Fixes: LAC users cannot create a new task from MyTasker.",
        "Bug Fixes: LAC users cannot see all tasks in the 'Assigned to Others' tab in MyTasker.",
        "Bug Fixes: Added the task management activities log at mytasker.",
        "Bug Fixes: Back button added on Rankings Form"
      ]
    },
    {
      id: 132,
      version: "4.6.7",
      date: "09-25-2024",
      highlights: [
        "Feature: Completed Tasks Added in MyTasker.",
        "Feature: LAC can delegate Tasks to assigned by person only.",
        "Feature: Added meeting alert notification 30 minutes before the meeting starts.",
        "Bug Fixes: Fixed the alignment issue in the module details section on the module screen.",
        "Bug Fixes: Fixed In a Full Access group, the owner is receiving duplicate messages when a subordinate sends a normal message.",
        "Bug Fixes: Fixed Unable to reassign the task from Archive.",
        "Bug Fixes: Fixed Modify option is not available for support tasks in MyTasker.",
        "Bug Fixes: Fixed Modifying a group task from MyTasker does not update the task for subordinates.",
        "Bug Fixes: Fixed Group chat count and added notification for group chat messages",
        "Bug Fixes: Updated the calendar feature to restrict attendees input to valid email formats only.Not allowed custom inputs.",
        "Bug Fixes: Update the user status functionality to show correct user status.",
        "Bug Fixes: Fixed Activity log updates twice when a task is moved to 'Done'.",
        "Bug Fixes: Added L1 and L2 support persons only can reply for support tickets",
        "Bug Fixes: Updated the columns and summary at Project Costing ",
      ]
    },
    {
      id: 131,
      version: "4.6.6",
      date: "09-19-2024",
      highlights: [
        "Feature: Project costing is added at admin Dashboard.",
        "Feature: Added activity logs for all messaging activities across Squad Chat, Task Chat, Team Chat, and Group Chat. This includes logs for sending messages, joining meetings, and attaching files.",
        "Feature: Added header descriptions for the task columns in my tasker timeline across all tabs. Additionally, organized the meeting tab tasks into separate sections: day-wise, week-wise, month-wise, and year-wise.",
        "Bug Fixes: Fixed Tasks are not modifying from modules.",
        "Bug Fixes: Removed project switching from view group task page.",
        "Bug Fixes: Removed Add sub task option for group tasks from MyTasker.",
        "Bug Fixes: Web-Support board crashing when i click on a task title",
        "Bug Fixes: Rank submission without empty feedback and validate with not allowing the initial spaces",
        "Bug Fixes: Fixed the bug of alert not being displayed when adding a group task without selecting a project from mytasker",
        "Bug Fixes: Fixed the meeting links so they generate correctly in the chat, matching the join button meeting link format.",
        "Bug Fixes: Set to allow modify events to extend the meeting time before the end time limit without exceeding it.",
        "Bug Fixes: Fixed the issue in FAQ's search functionality and back button in landing page",
        "Bug Fixes: Fixed the multiple select option roles simultaneously when assigning a member at team.",
        "Bug Fixes: Fixed the alignment issue where the priority level shifts up when displaying a subtask card within a task card.",
      ]
    },
    {
      id: 130,
      version: "4.6.5",
      date: "09-12-2024",
      highlights: [
        "Feature: Added activity logs for Task Management activities in the Support Board. This includes logs for Add, ToDo, In Progress, Roadblock, and Done activities.",
        "Feature: Avoid to add/create the Duplicate Rankings(While Award Rankings in the Date Range).",
        "Feature: Added meeting details, including meeting schedule date and duration time details, to the meeting task card in meeting tab.",
        "Bug Fixes: Get latest data from if we switch tabs in MyTasker.",
        "Bug Fixes: Optimized workflow sub task info at cards on planned board & support board",
        "Bug Fixes: When navigating from MyTasker to the Backlog screen to view subtasks, the project name is incorrect.",
        "Bug Fixes: Restrict automated replies to self assigned support tickets.",
        "Bug Fixes: Added a Platform column to support reports, which describes the platform through which the support ticket was raised.",
        "Bug Fixes: Modified the Activity Name column in the logs report to include both the activity and its sub-activity.",
        "Bug Fixes: Optimized the Average Ranks Calculation based on spread(xls) sheet formula.",
        "Bug Fixes: Chat count update on admin rankings",
        "Bug Fixes: A task alignment issue is occurring in the Planned board, specifically impacting subordinates.",
        "Bug Fixes: An error occurs, rather than a warning, when reassigning a task without a target date in the Backlog and Archive sections.",
        "Bug Fixes: Navigation report for user activities",
        "Bug Fixes: User activities report for task management",
        "Bug Fixes: Update email notifications to stop sending alerts for automatically deleted events and instead send notifications only for manually deleted events.",
        "Bug Fixes: Update attendees list to avoid empty user list in attendees list.",
        "Bug Fixes: Fixed subtask assignment order to start sub task",
        "Bug Fixes: Fixed group task which is delegated to me is not showing in my tasker",
        "Bug Fixes: Fixed sending a previous message again also attaches a new message without providing reply"

      ]
    },
    {
      id: 129,
      version: "4.6.4",
      date: "09-04-2024",
      highlights: [
        "Feature: The average user rankings are shown depending on the admin-selected date range.",
        "Feature: The whole ranking report for each individual user selected within the chosen time frame",
        "Feature: To make awarding ranks to specific users easier, a search box has been added to the award rankings screen.",
        "Feature: Private Messaging Feature for Group Tasks",
        "Feature: Calculated and incorporated response times into user activity logs for the activities documented in the first phase.",
        "Feature: Automatically send an acknowledgment message for support tickets when a user with the support role clicks on the chat icon for the first time, for inside and outside support tickets.",
        "Feature: Costing module.",
        "Bug Fixes: Fixed Send and Clear Icons and Buttons should be enabled or disabled depending on whether all fields are filled out",
        "Bug Fixes: Subtasks are now displayed by priority in MyTasker, and only one subtask can be started at a time.",
        "Bug Fixes: Fixed the issue in group Task chat where deleted messages were still visible on other users' screens.",
        "Bug Fixes: The search option is not functioning for tasks and subtasks in the Archive timeline screen.",
        "Bug Fixes: The search bar is displaying Archive support board tasks in the Timeline search results when looking up Timeline and Support board task IDs.",
        "Bug Fixes: When creating a new event without selecting a project name, the attendees' names incorrectly appear in the attendees selection list.",
        "Bug Fixes: Update the auto-delete functionality to prevent the calendar and my tasks pages from hanging when events and tasks are deleted after their completion time.",
        "Bug Fixes: Fixed While searching for Task ID in MyTasker, it’s not displaying correctly for the first time."
      ]
    },
    {
      id: 128,
      version: "4.6.3",
      date: "08-29-2024",
      highlights: [
        "Feature: The admin portal now includes a Sprint Report option in the All Reports dropdown menu, enabling administrators to generate detailed reports based on both sprint and project",
        "Feature: Rankings Report(Admin & User)",
        "Bug Fixes: Fixed group tasks are assigning to inactive people.",
        "Bug Fixes: The issue where the letter 'A' was automatically attached to tasks when using a template has been resolved",
        "Bug Fixes:The module page displays 'No data available' is resolved",
        "Bug Fixes: Beyond timeline(future) calendar tasks added in to MyTasker meetings and also added join meetings,complete task functionalities.",
        "Bug fixes: In module timeline when user add module with out give any task name, task description and target date it is not show warning alert",
        "Bug fixes: Removed save button in quick info card when create new calendar event.",
        "Bug Fixes: Completed calendar events still showing in dash board calendar and project calendar.",
        "Bug Fixes: When clicking More Options (ex : Add subtask, Modify, Move to Roadblocks ) & join confirm replace in the Meeting tab, it appears in the Team Tasker tab in My Tasker screen.This more option is removed for calendar tasks.",
        "Bug Fixes: Unable to initiate or join the call through the link generated for new group children Tasks",

      ]
    },
    {
      id: 127,
      version: "4.6.2",
      date: "08-22-2024",
      highlights: [
        "Feature: Delete Message in all chat windows",
        "Feature: Introduced an All Reports option in the admin side navigation bar, allowing admins to select report types and generate reports.",
        "Bug Fixes: Subgroup association with Main Group in modules.",
        "Bug Fixes: Resolved an issue where users were unable to add tasks to the timeline from MyTasker.",
        "Bug Fixes: Resolved an issue where, when modifying the main group task, any subordinate tasks that are in progress will now automatically be moved back to the 'To Do' status.",
        "Bug Fixes: Resolved an issue where the timeline name was missing when viewing task details under the 'All Tasks' tab in MyTasker.",
        "Bug Fixes: Resolved an issue where the activity log remained empty when a task was added using a template in the backlog screen.",
        "Bug Fixes: Fixed Sending a previous reply message to a new message without a reply.",
        "Bug Fixes: Modify normal task into group task added Limited & Full access option",
        "Bug Fixes: Unable to create a calendar event from dashboard and project calendar,",
        "Bug Fixes: The meeting isn't starting from the My Tasker Task Chat, which was created as an event from the Calendar.",
      ]
    },
    {
      id: 126,
      version: "4.6.1",
      date: "08-19-2024",
      highlights: [
        "Feature: Added meeting url for calendar events and calendar tasks.Hide calendar tasks from planned board, support board, archive and backlog timeline tasks. Add join button to navigate to meeting page for calendar tasks in my tasker. Calendar tasks will move to the top of My Tasker 30 minutes before the scheduled meeting time.These calendar tasks will remove from my tasker after exceed the meeting end time.",
        "Feature: Group tasks can now be added as either Limited Access or Full Access. Messages sent by Full Access contributors are visible to all group members, while message visibility is restricted for Limited Access contributors in group tasks.",
        "Feature: Added User Activity logs tab in admin portal. Activity logs report can be viewed from admin portal.",
        "Bug Fixes: In task chat, disabled reply to message option until the previous message api request is completed.",
        "Bug Fixes: In the task chat, if you attempt to reply to a message while a previous message API request is still pending, the reply tag disappears after receiving the API response.",
        "Bug Fixes: When I click on the More Actions button in MyTasker, the subtask labels in the Support Board are showing Backlog instead of Support Board.",
        "Bug Fixes: In the Teams section, when adding a new team member, if a user is selected from the dropdown, their details become editable.",
        "Bug Fixes: The Task ID and Subtask ID are appearing twice in the Subtasks view details on the module screen.",
        "Bug Fixes: The sender's name is not visible at the top in 1-on-1 chat after a message is sent.",

      ]
    },
    {
      id: 125,
      version: "4.6.0",
      date: "08-07-2024",
      highlights: [
        "Feature: Task completed reason added.",
        "Bug Fixes: Fixed the issue after creating a combined task by combining 2 tasks those tasks are disappeared from the Planned or support board , But they are visible in MyTasker and able to start.",
        "Bug Fixes: Subtasks not visible in the MyTasker when user filter with search input.",
        "Bug Fixes: When creating an calendar event with longer descriptions the event is not created.",
        "Bug Fixes: Able to create a roadblock for a subtask without a fill title field.",
        "Bug Fixes: The issue with group task activity logs has been fixed when the main group task is completed.",
        'Bug Fixes: The default module, team member, story points, and priority values are displayed in the "Add Task" modal in MyTasker',
        "Bug Fixes: The activity log displays incorrect information for subtask when moving to 'In Progress' or 'To Do'. ",
        "Bug Fixes: In the employee efficiency report, the project name column is displayed as 'Module' instead of 'Project'. ",
        "Bug Fixes: When clicking 'Change Status' for a subtask on the module screen, it moves directly to Don' instead of 'In Progress'. ",
        "Bug Fixes: Chat alignment issue in Tasks within the Timelines on the Archive screen.",
        "Bug Fixes: In view subtask can start the subtask assignment order -2 even after changing the subtask order without saving it.",
        "Bug Fixes: Automatically changed user status when user while working.",
        "Bug Fixes: Calendar event is not modified without select generate task.",
      ]
    },
    {
      id: 124,
      version: "4.5.9",
      date: "07-31-2024",
      highlights: [
        "Feature: The priority of an in-progress sub-task cannot be changed.",
        "Bug Fixes: Now we can't modify team members in calendar group events across both calendars even without selecting the Generate Ticket option.",
        "Bug Fixes: Resolved the alignment issue for multiple tags on the support board task.",
        "Bug Fixes: An alert message is now displayed when moving a task to a sprint without a timeline in the backlog.",
        "Bug Fixes: An alert is now displayed when a support board task is created without adding a team member.",
        "Bug Fixes: Fixed the issue where the activity log message was displaying empty when adding a task to the backlog.",
        "Bug Fixes: Added activity log and related timeline to which tasks added from calendar.",
        "Bug Fixes: The 'MyTasker' view group task page will display the same project name.",
        "Bug Fixes: When a support ticket is modified from the backlogs page, its story type does not change.",
        "Bug Fixes: Support Board, Planned Board, Squad Chat: Improve Performance and Optimized Queries.",
        "Bug Fixes: Fixed the issue after creating a combined task by combining 2 tasks those tasks are disappeared from the Planned or support board , But they are visible in MyTasker and able to start.",
        "Bug Fixes: Subtasks not vissible in the my tasker when user filter with search input.",
        "Bug Fixes: When creating an calendar event with longer descriptions the event is not created.",
        "Bug Fixes: Able to create a roadblock for a subtask without a fill title field."
      ]
    },
    {
      id: 123,
      version: "4.5.8",
      date: "07-26-2024",
      highlights: [
        "Bug Fixes: Fixed the creation and updating of modules with proper validation for the title and description.",
        "Bug Fixes: The Backlog route remains active even when navigating to Subtasks and Group task.",
        "Bug Fixes: The alignment issue has been fixed for the 'Add New Project' option in the top navigation bar.",
        "Bug Fixes: Incorrect timesheet report in admin panel project report.",
        "Bug Fixes: In the Support board and Planned board, users should be able to move tasks from 'In Progress' to 'To-do",
        "Bug Fixes: Add a loader and disable the 'Start' and 'Complete' buttons when clicked to prevent multiple API calls.",
        "Bug Fixes: When a user delegates a task, the in-progress time should be attributed to the person who delegated the task.",
        "Bug Fixes: subtask blocked tickets should not visible in MyTasker",
        "Bug Fixes: In MyTasker for blocking tickets of subtasks complete id's are not displaying.",
        "Bug Fixes: When modify events in Dashboard calendar and nav calendar that do not allow to modify date and time in to past",
        "Bug Fixes: Clicking the Reply button to send a message, and then cancelling, still attaches the original message. This issue is in all chat tabs.",
        "Bug Fixes: Not displaying a successful alert for edit, delete, create, Update in the Group screen (in admin). Alert message not getting displayed when moving a chat to archive and again reopening the archived chat.",
        "Bug Fixes: In subtasks, for blocked tickets, modify option should not be enabled",
        "Bug Fixes: In subtasks, the IDs of every blocked ticket and its corresponding blocking ticket should be displayed, similar to how they appear in the Planned and Support boards."
      ]

    },
    {
      id: 122,
      version: "4.5.7",
      date: "07-24-2024",
      highlights: [
        "Feature: Timesheet Flag on worked tasks to edit time.",
        "Feature: Tasks activity logs completed from backlogs, MyTasker, group tasks, and module level.",
        "Bug Fixes: Sub task message count updated on view sub task page, MyTasker",
        "Bug Fixes: The issue where multiple records were being inserted into the timesheet due to the confirm button on the task move modal being disabled after clicking.",
        "Bug Fixes: In side Navbar, Backlog and Archive options are not highlighting when we are navigating to another screen in that page.",
        "Bug Fixes: Acronyms in Project report is showing in small letters. Fixed this in admin reports where time is displayed.",
        "Bug Fixes: In Admin Group members, users list was not displayed in the dropdown menu when creating and modifying the group.",
        "Bug Fixes: In Team Tasker and Personal Tasker search input was not resetting when switching tabs.",
        "Bug Fixes: Fixed the issue where subtasks in MyTasker display a priority level even when none is set.",
        "Bug Fixes: Changed the colors for the main task ID and RB ticket ID to be the same color in the backlog.",
        "Bug Fixes: In 1-1 chat, searching with special characters or unknown names was causing issues.",
        "Bug Fixes: Responsive issue in priority level at personal task is fixed.",
        "Bug Fixes: Modules in side navbar is highlighting when we we click on any module.",
        "Bug Fixes: Alignment issue with blocked id and support buttons is fixed at Backlog",
        "Bug Fixes: Ensure that the event status and generated task fields are not automatically filled when creating events from the dashboard and inside the calendar.",
        "Bug Fixes: In the calendar, when an event is assigned to a group, the team members should not be modified.",
        "Bug Fixes: Displayed an error message instead of a warning when attempting to modify group members in a calendar event.",
        "Bug Fixes: Fixed an issue where the calendar modal wouldn't close on the dashboard when selecting a project.",
        "Bug Fixes: Only relevant calendar events will be displayed to the user",
        "Bug Fixes: In the modules, only stakeholders and project managers can make modifications.",
        "Bug Fixes: Adjusted delete card size in Calendar event deletion to resolve bug.",
        "Bug Fixes: When clicking on 'Follow-Up' in MyTasker, the message is updated with the username.",
      ]
    },
    {
      id: 121,
      version: "4.5.6",
      date: "07-18-2024",
      highlights: [
        "Feature: Only active tasks should be displayed in MyTasker, accessible from both the web and app platforms.",
        "Feature: Delegating a workflow task to another team member ensures all subtasks are assigned to them.",
        "Bug Fixes: Single account user does not contain account selection while create the Project.",
        "Bug Fixes: Fixed typo issue for forgot password in login screen.",
        "Bug Fixes: changed alert messages in add task in team taker.",
        "Bug Fixes: Fixed the issue of messages disappearing when the API response of a previous message is updated. This fix applies to 1-1 chat, group chat, task chat, subtasks chat, and teams chat.",
        "Bug Fixes: Fixed the issue where the cursor automatically shifted to the next line when a user typed a message and pressed enter.",
        "Bug Fixes: Added validation for phone numbers in the chatbot when a user requests a demo. The validation checks whether the input consists of 10 digits.",
        "Bug Fixes: Fixed the issue of null values appearing instead of zeros for pending points and completed points in the planned board, support board, and backlog.",
        "Bug Fixes: Timesheet reporting: The getAllCount and getIndividualWorking APIs have been updated to retrieve time based on the task's start time and end time.",
      ]
    },
    {
      id: 120,
      version: "4.5.5",
      date: "07-11-2024",
      highlights: [
        "Feature: Chatbot added.",
        "Feature: User Active and De-Active on Account level (Client Admin - Under Teams).",
        "Feature: Super Admin - Account Users and project information under Accounts Profile.",
        "Feature: Added Validation for only one task/subtask in progress.",
        "Feature: Added MyTasker sub-task listing, delegate, modify, complete, start, and chat functionalities.",
        "Feature: Planned board displayed subtask information on cards.",
        "Feature: Added Search at Delegate task window.",
        "Feature: Highlighted active page icons at top nav bar.",
        "Feature: Added activity logs for tasks created by calendar events.",
        "Feature: In User, Project creation with the account selection added on Dashboard",
        "Feature: In User, Project creation with the account selection added on Top Nav",
        "Bug Fixes: Changed sprint velocity graph from line graph to bar graph.",
        "Bug Fixes: Added a new column Number of Accounts for super admin in admin reports.",
        "Bug Fixes: Search-chat is changed in all chats, overflowing the text on cross is fixed.",
        "Bug Fixes: chat count is disappeared after reading message at backlog.",
        "Bug Fixes: Fixed the undefined at task id at module",
        "Bug Fixes: Added Required Fields for Event title and description",
      ]
    },
    {
      id: 119,
      version: "4.5.4",
      date: "06-28-2024",
      highlights: [
        "Feature: Chat Search in All Tasks-Users can now search within chat messages across all tasks, making it easier to find relevant conversations quickly.",
        "Feature: Group Task Addition from MyTasker Page- Users can now add group tasks directly from the MyTasker page",
        "Feature: Visibility of Project Time Reports and Support Ticket Reports- Project time reports and support ticket reports are now visible to all team members except those with the role of Limited Access Contributor.",
        "Bug Fixes: Support Board Creation Issue- Fixed an issue where support boards were not being created for some projects.",
        "Bug Fixes: User Level Report Date Range Display- Corrected an issue where the project time report did not display the selected date range correctly.",
        "Bug Fixes: Support Report Column Names - Modified the column names in support reports to display date and time values correctly when expanded.",
        "Bug Fixes: Client (Accounts) Admin Time Reports - In the Client (Accounts) Admin view, time reports for team members with different email domains are now visible.",
        "Bug Fixes: Support Ticket Report Display -Support ticket reports are now correctly displayed based on the selected project.",
        "Bug Fixes: New User Account Assignment - Ensured that new users created from the landing page are correctly assigned to the Wetasker Account.",
        "Bug Fixes: Group Task Display Enhancement- Group tasks will now display the creator's name and target date on subordinate tasks when viewed in the View Group Tasks section.",
        "Bug Fixes: Group Task Label is added to the module section for any group tasks.",
      ]
    },
    {
      id: 118,
      version: "4.5.3",
      date: "06-25-2024",
      highlights: [
        "Feature: Feature Enable/Disable: Administrators can now manage the availability of specific features within Wetasker. This capability allows for greater customization and control over platform functionalities based on organizational needs.",
        "Feature: Project and Team Separation: Projects and teams have been segregated into distinct accounts."
      ]
    },
    {
      id: 117,
      version: "4.5.2",
      date: "06-21-2024",
      highlights: [
        "Feature: Events cannot be added for dates in the past.",
        "Feature: Deleting an event in the Calendar now completes linked Tasks automatically.",
        'Features: Added "Responded By" column in response time reports.',
        'Features: Added "Ticket Generated" column in response time reports.',
        'Features: Added "Created Date" and "Completed Date" columns in Total Resolved report.',
        "Feature: web/android/ios labels displayed on support board",
        "Bug Fixes: Resolved issue with NA values in task name column in Total Tickets report.",
        "Bug Fixes: Fixed issue causing partial task names when exporting the report.",
        "Bug Fixes: Fixed support board displaying Tasks from the planned board.",
        "Bug Fixes: Kudos cannot be given with any empty spaces anymore.",
      ]
    },
    {
      id: 116,
      version: "4.5.1",
      date: "06-18-2024",
      highlights: [
        "Feature: Support Role Assignment on Team Addition: When new team members are added, they can now be assigned a support role automatically.",
        "Feature: Workflow Display: The support board now includes a workflow view, providing better visibility and management of ongoing support tasks.",
        "Feature: Choice Checkbox: A new checkbox has been added, giving users the option to choose whether to convert a calendar event into a task.",
        "Feature: Target Date: Tasks created from calendar events will now automatically have the event date set as their target date.",
        "Feature: Backlog Placement: Tasks created with event dates beyond the timeline will automatically be placed in the backlog.",
        "Bug Fixes: FAQ Editing: Administrators can now successfully edit FAQs without issues.",
        "Bug Fixes: Null Value Fix: Resolved the issue where null values were appearing in the archive.",
        'Bug Fixes: Phone Number Edit: The drop-down for editing phone numbers has been removed from the "Edit Team Member Details" section to streamline the editing process.',
        "Bug Fixes: Kudos Description Warning: Added a warning to prevent the addition of empty Kudos without a description, ensuring all Kudos entries are meaningful and complete.",
      ]
    },
    {
      id: 115,
      version: "4.5.0",
      date: "06-14-2024",
      highlights: [
        "Feature: Calendar events are now automatically converted into tasks.",
        "Feature: Main task owners can complete group tasks along with their subordinate tasks.",
        "Bug Fixes: Updated email content and timing for event creation notifications.",
        "Bug Fixes: Fixed an issue where pressing enter on the keyboard for profile display name resulted in an upgrade license error.",
        "Bug Fixes: Resolved an issue where scrolling was not working on the Kudos reason section.",
        "Bug Fixes: Support Ticket Reports: Updated support ticket reports to include response time changes in seconds and details of the support team."
      ]
    },
    {
      id: 114,
      version: "4.4.9",
      date: "06-11-2024",
      highlights: [
        "Feature: FAQ's are distributed into different sections from both admin and user section.",
        "Feature: Unified Meeting Links for Group Task: All subordinate and main task of a group task will now generate the same meeting link.",
        'Bug Fixes: Fixed the issue preventing users from adding Kudos for team members.',
        "Bug Fixes: Resolved the issue where the chat count was not updating after reading messages, ensuring the count reflects the actual number of unread messages on the Planned board.",
        "Bug Fixes: Responsive: Resolved alignment issue with chat count in the Archive section",
        "Bug Fixes: Responsive: Fixed visibility issue for selecting a team member when adding a task",
        "Bug Fixes: Responsive: Made the down scroll bar in the chat section visible",
      ]
    },
    {
      id: 113,
      version: "4.4.8",
      date: "06-07-2024",
      highlights: [
        "Feature: Search for Archived Tasks from Any Timeline.",
        'Bug Fixes: Resolved the issue where the "Verify" and "Reassign" buttons were inactive for completed tasks in the backlog and archive.',
        "Bug Fixes: Implemented accurate filtering and reporting for support tickets based on the specific date of creation."
      ]
    },
    {
      id: 112,
      version: "4.4.7",
      date: "06-04-2024",
      highlights: [
        "Bug Fixes: MyTasker- Group assignments can be done specifically to the groups of that project.",
        "Bug Fixes: Archive Timeline Navigation: Scroll bars have been added to the side navbar for better navigation.",
        "Bug Fixes: Dashboard Page Updates:Fixed the issue where the message count was overflowing out of the designated box.",
        "Bug Fixes: Chat Box UI Improvements:Fixed the issue where the chat box heading title was overlapping with the close icon.",
        "Bug Fixes: Account Creation Enhancements:Removed the up and down icons for the phone number field in the Account Creation form.Implemented phone number validation to ensure correct input.",
        "Bug Fixes: Empty support tickets will have auto generated message."
      ]
    },
    {
      id: 111,
      version: "4.4.6",
      date: "05-31-2024",
      highlights: [
        "Feature: Support Board Reports: Introduced the ability to generate detailed reports for support tickets. Users can now easily track and analyze ticket statuses, response times, and resolutions through the new Support Board Reports feature.",
        "Bug Fixes: Tasks Deadline Indicator:Fixed an issue where tasks were incorrectly turning red on the day of their deadline.",
        "Bug Fixes: Task Modification in MYTASKER: Resolved an issue that prevented users from modifying tasks in MYTASKER. All tasks, except those belonging to Subordinate group tasks or Roadblocks, can now be edited as needed.",
      ]
    },
    {
      id: 110,
      version: "4.4.5",
      date: "05-30-2024",
      highlights: [
        "Bug Fixes: Modify option issue fixed for normal tasks",
      ]
    },
    {
      id: 109,
      version: "4.4.4",
      date: "05-29-2024",
      highlights: [
        "Feature: Account Creation: Users can now see the type of account along with number of users and projects count during account creation.",
        "Bug Fixes: Report Search: Resolved issue with report search during data loading.",
        "Bug Fixes: Reassign and Verify: Fixed issues with reassigning and verifying tasks from the archive.",
        "Bug Fixes: Group Task Modification: Resolved issue with modifying the group task name reflecting for all the subordinate tasks.",
      ]
    },
    {
      id: 108,
      version: "4.4.3",
      date: "05-24-2024",
      highlights: [
        "New Feature: Introduced an AI-driven task generation feature that allows users to create tasks quickly and accurately by leveraging historical data, task patterns, and relevant context.",
        "Bug Fixes: Improved the responsive design for the Backlog and MyTasker pages to enhance the user experience on different devices.",
        "Bug Fixes: Resolved an issue with the report search functionality, ensuring accurate and efficient search results.",
        "Bug Fixes: Fixed a bug in the Backlog section where reassigning tasks was not functioning correctly.",
        "Bug Fixes: Corrected the log activity feature for task reassignment, ensuring proper tracking and recording of task reassignment actions."
      ]
    },
    {
      id: 107,
      version: "4.4.2",
      date: "05-22-2024",
      highlights: [
        'Bug Fixes: Task Movement Update: When moving a task to "Blocked" from "Support" with the existing title, the task will now appear in the "To Do" section of the support board.',
        "Bug Fixes: Blocked Task Restrictions: Blocked tasks will no longer have any action options available in the Backlog or MyTasker sections.",
        "Bug Fixes: Activity Log Corrections: All activity logs will now display the corrected actions for better clarity and tracking.",
        "Bug Fixes: Task Modification in MyTasker: Users can now modify backlog tasks directly from MyTasker.",
        'Bug Fixes: Subtask Viewing: The "View Subtasks" option is disabled for tasks under modules that do not contain any subtasks.',
        "Bug Fixes: Group Report Enhancements: Improved sorting and search functionalities have been added to group reports.",
        "Bug Fixes: Support Board Refresh Rate: The refresh rate of the support board has been reduced to 30 seconds for more timely updates.",
        "Bug Fixes: Dependent Group task will be moved to timeline along with the main task."
      ]
    },
    {
      id: 106,
      version: "4.4.1",
      date: "05-17-2024",
      highlights: [
        "Bug Fixes: Notification onesignal id is updated.",
        "Bug Fixes: Disable template option from support board add task modal",
        "Bug Fixes: Creating a blocking task for adding a roadblock with the same Task Name.",
      ]
    },
    {
      id: 105,
      version: "4.4.0",
      date: "05-15-2024",
      highlights: [
        "Feature: Assigning a Roadblock to an existing task.",
        "Feature: Project Reports can now be organized and sorted by Groups.",
        "Responsive Fixes: Aligned cancel button when changing status on module screen.",
        "Responsive Fixes: Fixed cancel button on exit group screen.",
        "Responsive Fixes: Addressed text alignment issue while raising support tickets.",
        "Responsive Fixes: Resolved space issue on profile page.",
        "Responsive Fixes: Fixed scrolling issue with module name, priority, team member, and target time on add/modify task.",
        "Responsive Fixes: Fixed project dropdown visibility on dashboard.",
        "Responsive Fixes: Adjusted alignment issue on delete sprint.",
        "Responsive Fixes: Corrected display of tasks with deadlines in dashboard calendar.",
        "Bug Fixes: Disable template option from support board add task modal",
        "Bug Fixes: Updated task modal across all screens to ensure consistent task creation.",
        "Bug Fixes: Implemented warning message prompting users to add text when no input is entered.",
        "Bug Fixes: Corrected activity log time for subtasks.",
        "Bug Fixes: Adjusted kudos category display in table.",
        "Bug Fixes: Added hyperlinks for task chat links.",
        "Bug Fixes: Updated the pathname in URL for the support board.",
        'Bug Fixes: Verified entered information on the "Contact Us" page.',
        "Bug Fixes: Resolved bug showing two chats when task is assigned by me.",
        "Bug Fixes: Corrected display of tasks with deadlines in dashboard calendar.",
        "Bug Fixes: Unplanned board message refresh timer decreased to 10 seconds",
        "Bug Fixes: Parent group task not complete until children group tasks are completed from mytasker",
        "Bug Fixes: limited access contributor search updated at mytasker all tasks",
      ]
    },
    {
      id: 104,
      version: "4.3.9",
      date: "05-10-2024",
      highlights: [
        "Feature: Start Task from MyTasker: Users can now initiate tasks directly from MyTasker, even if they are located in the Backlog and assigned to any of the Planned Boards.",
        "Feature: Default Module for New Projects: With this update, a default module is automatically added when creating a new project.",
        "Feature: Task Assignment to Default Module: Tasks that are not explicitly assigned to a specific module will now be automatically placed in the default module.",
        "Bug Fixes: Complete Task Functionality in Mytasker",
        "Bug Fixes: Automatic Display Name Initialization: if a user does not have a display name set, the system will automatically generate initials from the user's full name and display them in the cards.",
      ]
    },
    {
      id: 103,
      version: "4.3.8",
      date: "05-10-2024",
      highlights: [
        "Feature: All Tasks Search implemented on MyTasker",
        "Feature: On MyTasker, PersonalTasker Enhancements and Activity",
        "Bug Fixes: On View Subtasks, SubTask added to different project/not created was fixed",
        "Bug Fixes: Start Task(moving task from ToDo to InProgress) pop-up confirmation UI updates and Project label along with TaskID issue fixed on all Boards(Sprint, Support and MyTasker)",
        "Bug Fixes: Add Tasks error messages text modified from User story to dynamic(task) label",
      ]
    },
    {
      id: 102,
      version: "4.3.7",
      date: "05-03-2024",
      highlights: [
        "Feature: Multiple Roadblock assigned to 1 solution Task.",
      ]
    },
    {
      id: 101,
      version: "4.3.6",
      date: "04-26-2024",
      highlights: [
        "Bug Fixes: On MyTasker, Delegate To name Undefined and removed Move To Progress and Done from more Actions",
        "Bug Fixes: On View Subtasks, Subtask Disappears after modification was fixed"
      ]
    },
    {
      id: 100,
      version: "4.3.5",
      date: "04-24-2024",
      highlights: [
        "Feature: Introducing one-click task initiation, delegation, and status follow-up on the My Tasker page for streamlined task management.",
        "Bug Fixes: On MyTasker, Tasks redirection like AllTasks (or) PlannedBoard (or) SupportBoard labels moved to more actions"
      ]
    },
    {
      id: 99,
      version: "4.3.4",
      date: "04-22-2024",
      highlights: [
        "Bug Fixes: Group Chat & Limited Access Contributor can see only AT(Assigned to) & AB(Assigned by) info at chat window",
      ]
    },
    {
      id: 98,
      version: "4.3.3",
      date: "04-19-2024",
      highlights: [
        "Bug Fixes: Notification issue fixed",
        "Bug Fixes: Chat count refresh issue fixed",
        "Bug Fixes: Roadblock modal UI fixes(label changes and Task ID included) on planned and unplanned board blocked list"
      ]
    },
    {
      id: 97,
      version: "4.3.2",
      date: "04-16-2024",
      highlights: [
        "Feature: Roadblock labels added on Planned and Support Boards with Task ID on Blocked List",
        "Bug Fixes: Roadblocks list UI modifications and ordered with latest roadblock tasks on top",
      ]
    },
    {
      id: 96,
      version: "4.3.1",
      date: "04-12-2024",
      highlights: [
        "Bug Fixes: Empty Message in chat issue fixed.",
        "Bug Fixes: Group Update should work automatically when a team member is removed",
        "Bug Fixes: Main Group Name( example: AgileNexApp or AgileDemo) cannot be changed."
      ]
    },
    {
      id: 95,
      version: "4.3.0",
      date: "04-10-2024",
      highlights: [
        "Bug Fixes: Added workflow labels on Priority Board.",
        "Bug Fixes: Roadblocks Support/priority labels fixes on Backlogs",
        "Feature: Roadblock labels added on My Tasker(Task ID)",
        "Bug Fixes: Blocked list differentiated based on Boards(planned and unplanned boards)"
      ]
    },
    {
      id: 94,
      version: "4.2.9",
      date: "04-04-2024",
      highlights: [
        "Feature: Modifying task to group task.",
        "Feature: Adding group tasks from template.",
        "Feature: Automatically assigned roadblock job (converting to Task) to sprint during creation(Planned, Un-Planned boards and MyTasker)",
        "Bug Fixes: Chat window scrolling issue fixed.",
        `Bug Fixes: Updated options menu by removing "Add to Module" form Backlogs for more options.`,
        "Feature: Blocked and Resolutions Tabs are differentiated on User Major Issues",
        "Feature: More actions added and includes task details, status update and chat"
      ]
    },
    {
      id: 93,
      version: "4.2.8",
      date: "03-29-2024",
      highlights: [
        "Feature: User status will change to 'Not Available' after 10 minutes of inactivity or upon logging out.",
        "Feature: Added search and archive functionality to group chats.",
        "Bug Fixes: Added workflow labels to Backlogs, My Tasker, Modules, and View Templates.",
        `Bug Fixes: Updated options menu by removing "Add to Module" and "View Subtasks" for more options.`,
        "Bug Fixes: Removed Project Switching option when on Subtask Screen.",
      ]
    },
    {
      id: 92,
      version: "4.2.7",
      date: "03-27-2024",
      highlights: [
        "Bug Fixes: UI Responsive issues fixed",
      ]
    },
    {
      id: 91,
      version: "4.2.6",
      date: "03-27-2024",
      highlights: [
        "Feature: Support ticket shows different levels- based on the team response( card color change).",
        "Feature: Boards for Team Taskers and Personal Taskers are distinguished by tabs.",
        "Feature: Regardless of project switching, the user can create a user story using Team Tasker.",
      ]
    },
    {
      id: 90,
      version: "4.2.5",
      date: "03-22-2024",
      highlights: [
        "Feature: Addition of Display Name of user in Profile.",
        "Feature: Chat will show only Assigned To and Assigned By and the rest of the contributors are shown in CC.",
        "Improvements: Resolved Performance Issues.",
        "Bug Fixes: Responsive Design for Viewing Subtasks and Templates.",
        "Bug Fixes: Removed Workflow Label from Backlogs, My Tasker, Modules, and View Templates.",
        "Bug Fixes: Updated Alert Text Labels for Adding Personal Tasks."
      ]
    },
    {
      id: 89,
      version: "4.2.4",
      date: "03-20-2024",
      highlights: [
        "Bug Fixes: All tasks added and should save as workflow templates.",
        "Bug Fixes: UI modifications for all add/modify and support tasks modals.",
        "Bug Fixes: Use Template option is given on add support task under support board to view the templates.",
        "Bug Fixes: More actions removed from add task modal from backlogs",
        "Bug Fixes: Chat icons moved to left in backlogs, archive, my tasker and module level(main task and subtasks)",
        "Bug Fixes: Personal Task converted into Project Task from My Tasker"
      ]
    },
    {
      id: 88,
      version: "4.2.3",
      date: "03-15-2024",
      highlights: [
        "Feature: Added chat support for reporting bugs with tickets raised by the user being shown to them.",
        "Feature: Removed access for completed modules, squad chat, group chat, and teams for LAC.",
        `Feature: Enhanced "My Tasks" by listing tasks assigned to and assigned by.`,
        "Feature: Introduced a careers page on Wetasker.",
        "Bug Fixes: Resolved issue allowing completion of tasks without subtasks which were deleted.",
        `Bug Fixes: Fixed display of "Complete Task" option on the Task tab when tasks are present under Sprint/Support Board.`,
        "Bug Fixes: Corrected display of all personal Gmail users under projects in the dashboard chat.",
        "Bug Fixes: Addressed the Main Task/User Story modify alert warning with a prompt to select a valid target date.",
        `Bug Fixes: Moved project and Epic/Major Module labels and redirection to "More Actions" from MyTasker Cards.`
      ]
    },
    {
      id: 87,
      version: "4.2.2",
      date: "03-12-2024",
      highlights: [
        "Feature: Group chat added",
        "Feature: Subtask comments can add from all status (add(todo), inprogress, done and roadblock)",
        "Feature: Display subtask comments information",
        "Feature: The option to create roadblocks is available for subtasks while they are in progress",
        "Feature: When generating an roadblock, a subtask is immediately created.",
        "Feature: Subtask completion occurs once all roadblock tasks have been completed.",
        "Feature: Subtasks that belong to a roadblock and cannot have a delete option",
        "Feature: Comments option is available to all Tasks(under backlogs and modules) and Subtask(modules)",
        "Bug FIxes: Warning message updated while modifying the subtask accordingly"
      ]
    },
    {
      id: 86,
      version: "4.2.1",
      date: "03-08-2024",
      highlights: [
        "Feature: created one Meeting link for group chat, user chat, squad chat",
        "Feature: Disabled Send Button When file upload on chat window",
        "Feature: Meeting links are changed to alpha numerical for user chat, squad chat"
      ]
    },
    {
      id: 85,
      version: "4.2.0",
      date: "03-06-2024",
      highlights: [
        "Feature: Workflow Chat Added",
        "Bug Fixes: Report Bug Creating multiple times issue fixed",
        "Bug Fixes: Side Navigation order changed and Chat room changed to Group",
        "Bug Fixes: Increased the File size for sharing the documents in chat"
      ]
    },
    {
      id: 84,
      version: "4.1.9",
      date: "03-01-2024",
      highlights: [
        "Feature: Remove selected labels(story points, % of task and update task) from planned and unplanned board",
        "Bug Fixes: Redundant support tickets were eliminated from Backlogs/Tasks",
        "Bug fixes: Group task Assignment details shown with group members names",
        "Bug Fixes: Main Group task completion dependency on Other dependent tasks",
        "Bug Fixes: Undefined name from warning label removed",
      ]
    },
    {
      id: 83,
      version: "4.1.8",
      date: "02-28-2024",
      highlights: [
        "Feature: One Task can be assigned to a group of people",
        "Feature: Default values added to creating a task",
        "Bug Fixes: The user can end the task only when all subtasks and roadblocks are completed/done",
        "Bug Fixes: Restrictions on Task Status have been added so that you can view and add subtasks on the Task/UserStory status from MyTasker, Backlogs, and Module User Stories.",
        "Bug Fixes: Workflow labels have been introduced to backlogs, module`s user stories, and MyTasker.",
        "Bug Fixes: Enable the save button when modifying the User Story or Main Task and close the warning message for mandatory fields.",
        "Feature: Admin can reset or generate a new password for the user.",
      ]
    },
    {
      id: 82,
      version: "4.1.7",
      date: "02-22-2024",
      highlights: [
        "Feature: Task Meeting Link Added at task chat window",
        "Feature: Ability to create the subtask with new details(acceptance criteria and user selection) and modifying",
        "Feature: Subtask Priority Reordered using drag and drop",
        "Feature: Add and Delete Subtasks by Priority",
        "Feature: Subtask to be creating a order of completion(workflow)",
        "Feature: Workflow Template can Create from Userstories Templates and Subtasks",
        "Bug Fixes: Subtask restrictions removed while creating from Backlogs(Timeline restrictions removed)"
      ]
    },
    {
      id: 81,
      version: "4.1.6",
      date: "01-31-2024",
      highlights: [
        "Bug Fixes: Mobile responsive issues fixed.",
        "Bug Fixes: My Tasks Chat issue fixed.",
        "Major Fixes: Complete UserModule Code Organization and Refactoring with CRUD Operations",
      ]
    },
    {
      id: 80,
      version: "4.1.5",
      date: "01-24-2024",
      highlights: [
        "Feature: Mobile responsive added.",
        "Bug Fixes: Updated Wetasker call link with Task ID.",
        "Bug Fixes: Fixed Task ID displayed is wrong Task ID while remove task from Timeline.",
        "Bug Fixes: Fixed Module closed successfully message while closing the Module.",
        "Bug Fixes: Fixed Dashboard ToDo action labels and action fixes",
        "Bug Fixes: Hide Stakeholder User role permissions"
      ]
    },
    {
      id: 79,
      version: "4.1.4",
      date: "01-18-2024",
      highlights: [
        "Bug Fixes: In Backlogs and Modules, Change status of user story(from ToDo to Inprogress (and/or) Inprogress to Done) issue fixed",
      ]
    },
    {
      id: 78,
      version: "4.1.3",
      date: "01-11-2024",
      highlights: [
        "Bug Fixes: Modules screen crashing issue fixed.",
        "Bug Fixes: Roadblock screen navigate to timelines if user role is contributor & LAC",
      ]
    },
    {
      id: 77,
      version: "4.1.2",
      date: "01-09-2024",
      highlights: [
        "Bug Fixes: User can change the status when they are in module page.",
        "Bug Fixes: Past target dates are not allowed while creating module.",
        "Bug Fixes: Subtask id added in subtask at module.",
      ]
    },
    {
      id: 76,
      version: "4.1.1",
      date: "01-03-2024",
      highlights: [
        "Bug Fixes: Redux alert issue fixed",
        "Bug Fixes: Web Notifications Enabled to WeTasker",
        "Bug Fixes: Case-Sensitive Project Name Validation.",
        "Bug Fixes: Roadblock Chat is working from Epic(Module)",
      ]
    },
    {
      id: 75,
      version: "4.1.0",
      date: "12-27-2023",
      highlights: [
        "Bug Fixes: Resolved chat box alignment issues.",
        "Bug Fixes: Eliminated mandatory fields when adding a user story to the Kanban board.",
        "Bug Fixes: Resolved Roadblock chat box issues.",
        "Bug Fixes: When you add a task from the Kanban board, it will be assigned to the Scrum Master by default. If no person is selected for the task, it will still appear on the Kanban board",

      ]
    },
    {
      id: 74,
      version: "4.0.9",
      date: "12-21-2023",
      highlights: [
        "Bug Fixes: Resolved issues related to property for Backlog & Squad.",
        "Bug Fixes: Chat window Draggable & Resizable.",
      ]
    },
    {
      id: 73,
      version: "4.0.8",
      date: "12-15-2023",
      highlights: [
        "Bug Fixes: Resolved issues related to property file names and duplicate file names, consolidating them into fewer columns for modification.",
        "Bug Fixes: Archive of user stories was resolved and moved with in the Sprint file.",
      ]
    },
    {
      id: 72,
      version: "4.0.7",
      date: "12-14-2023",
      highlights: [
        "Bug Fixes: Sprint with pending and completed user stories cannot be deleted.",
        "Bug Fixes: Pending user stories will moves into a next Sprint/Backlogs when sprint deleted.",
        "Bug Fixes: Pending user stories in a currently ending Sprint automatically moves into a next default Sprint ( automatically created if there are no new sprints created).",
        "Bug Fixes: Verify and reassign User Stories access given only to PO and PM.",
        "Bug Fixes: Chat window enlarged and resolution fixed- ui fixes.",
      ]
    },
    {
      id: 71,
      version: "4.0.6",
      date: "12-08-2023",
      highlights: [
        "Feature: Creating a default sprint involves checking if a sprint is not already available. If there is no existing sprint, the process includes creating a new one and moving all pending stories into this newly created sprint.",
        "Bug Fixes: Preventing duplicate task names for Roadblock while creating roadblock.",
        "Bug Fixes: Preventing duplicate template names while created save new template and add&save template.",
      ]
    },
    {
      id: 70,
      version: "4.0.5",
      date: "12-05-2023",
      highlights: [
        "Feature: Managing Backlog Tasks on Dashboard Todo and navigates to backlog board.",
        "Bug Fixes: More actions added based on user role and along with status change( i.e todo to in-progress or in-progress to done or in-progress to backlog(to created roadblock)) to planed and unplanned boards.",
        "Bug Fixes: Modified dashboard top navigation To Do label into My Tasks, dashboard ToDo to My Tasker and Personal ToDo to My Personal Tasker.",
        "Bug Fixes: Search on Todo in the dashboard with card numbers(story Id/task Id).",
        "Bug Fixes: Template will saving without Story points and priority points.",
        "Bug Fixes: Templates saved with duplicate Template names also added.",
      ]
    },
    {
      id: 69,
      version: "4.0.4",
      date: "11-24-2023",
      highlights: [
        "Bug Fixes: Fixed chat messages bunching issue in tasks chat,user chat, group chat and roadblock chat."
      ]
    },
    {
      id: 68,
      version: "4.0.3",
      date: "11-22-2023",
      highlights: [
        "Feature: Enhanced Dashboard To-Do with additional actions along with status change( i.e todo to in-progress or in-progress to done) for active Sprint/ Kanban board",
        "Feature: Moving tasks from inprogress to todo from Kanban/ Sprint board.",
        "Bug Fixes: Label fix for 'not planned board' to 'unplanned board' on dashboard todo.",
        "Bug Fixes: Improved Chatbot functionality.",
        "Bug Fixes: Fixed the issue with Scroll bar on top nav for switching projects."
      ]
    },
    {
      id: 67,
      version: "4.0.2",
      date: "11-17-2023",
      highlights: [
        "Feature: Option to enter hrs for task time hrs while adding or modifying.",
        "Feature: Video Conference Added In Group Chat.",
        "Feature: Contributors are not authorized to add team members and update employee status within the project.        ",
        "Bug Fixes: Video Conference Meeting Name Issue Fixed.",
        "Bug Fixes: Removed loader when user clicks on task name for task detail."
      ]
    },
    {
      id: 66,
      version: "4.0.1",
      date: "11-15-2023",
      highlights: [
        "Feature: Video Conference Added In Task Chat & Individual Chat.",
        "Feature: Generating Meeting link with out moderator credentials.",
        "Bug Fixes: Responsive TopNavBar Fixed."
      ]
    },
    {
      id: 65,
      version: "4.0.0",
      date: "11-10-2023",
      highlights: [
        "Copy of 3.0.40",
      ]
    },
    {
      id: 64,
      version: "4.0.0",
      date: "11-10-2023",
      highlights: [
        "Feature: Project Report Added at Employee Reports.",
        "Feature: TaskChat feature is added to todo tasks under dashboard's to-do.",
      ]
    },
    {
      id: 63,
      version: "3.0.39",
      date: "11-10-2023",
      highlights: [
        "Feature: In dashboard Todo, User can easily navigate to there respective board like sprint, backlogs and modules.",
        "Feature: Sorting completed userstories to bottom in active sprint on backlog page.",
        "Feature: Automatic default sprint board for first 30 days when a new project is added.",
        "Feature: Added scrollbar for view task details in Backlog tab.",
        "Feature: Increased File Upload Size Limit.",
        "Feature: Added validations for support ticket.",
        "Bug Fixes: Task details are not showing for some project when we click on Task.",
        "Bug Fixes: Enhanced Chatbot Responses.",
      ]
    },
    {
      id: 62,
      version: "3.0.38",
      date: "11-06-2023",
      highlights: [
        "Bug Fixes: Sprint board is disappearing for some projects.",
        "Bug Fixes: Message delivery time correction."
      ]
    },
    {
      id: 61,
      version: "3.0.37",
      date: "11-02-2023",
      highlights: [
        "Feature: Dashboard Search For Project.",
        "Feature: Scroll bar at project shifting.",
        "Feature: Contributors Cannot Modify Support Board User Stories.",
        "Bug Fixes: Dashboard Active User stories string Changed to dynamically",
        "Bug Fixes: Background color Topnav web site",
        "Bug Fixes: Fixed background color in modules for the 'complete status' label",
        "Bug Fixes: User Profile Efficiency Report.",
        "Bug Fixes: Top Navigation Chat fix.",
        "Bug Fixes: Showing Task status, task completed date at Modules at task info.",
        "Bug Fixes: Removed 'Add to Support Board' Option for Support Board Tickets.",
        "Bug Fixes: To Do on Top Navigation shows all your individual tasks and displays task's details upon clicking."
      ]
    },
    {
      id: 60,
      version: "3.0.36",
      date: "10-30-2023",
      highlights: [
        "Feature: Kanban board auto-created during project setup.",
        "Feature: Verify tasks from the Archive.",
        "Feature: individual Employee Efficiency available in user profile.",
        "Feature: Contributors can not modify userstories once it's moved into Sprint.",
        "Bug Fixes: Removed popup for chat messages.        ",
        "Bug Fixes: Updated Sprint Velocity Query on the Reporting Server.",
      ]
    },
    {
      id: 59,
      version: "3.0.35",
      date: "10-20-2023",
      highlights: [
        "Feature: Verified by information will display on hovering on verify from any module.",
        "Bug Fixes: Chatbot on landing page is responding with app specific details.",
        "Bug Fixes: Hide and unhide for Side navigation is fixed.",
        "Bug Fixes: Kanban Roadblock ticktets will be in Kanban.",
        "Bug Fixes: Add/ delete/ modidify Sqaud members.",
        "Bug Fixes: Blank page while Modifying Sprint got fixed.",
        "Bug Fixes: Email content for new users invitation and registration changed with the default password.",
      ]
    },
    {
      id: 58,
      version: "3.0.34",
      date: "10-18-2023",
      highlights: [
        "Feature: Userstories will be sorted according to ids on both the boards and Backlogs.",
        "Feature: Attach the image while contacting support.",
        "Feature: Task chat notification email will be sent to Assigned To, Assigned By, Scrum Master, Product Owner.",
        "Feature: Tick mark on Active Sprint/ Kanban board for verified UserStories.",
        "Feature: Filter the userstories of particular user by clicking on initials on Backlog.",
        "Feature: Restricted  access to a Contributor to modify a UserStory.",
        "Bug Fixes: Search functionality in Backlog.",
        "Bug Fixes: Modify and assign Roadblock ticket.",
        "Bug Fixes: add/modify/delete squad member.",
      ]
    },
    {
      id: 57,
      version: "3.0.33",
      date: "10-13-2023",
      highlights: [
        "Feature: Image from Support to go to the message of the userstory in the Kanban board.",
        "Bug Fixes: User initial Fixes at backlogs.",
        "Bug Fixes: Bug fix of the overdue Date of user stories.",
        "Bug Fixes: Verify  completed userstory from epic.",
        "Bug Fixes: Roadblock from Kanban will remain on Kanban.",
      ]
    },
    {
      id: 56,
      version: "3.0.32",
      date: "10-11-2023",
      highlights: [
        "Feature: Assigned By & Assigned To Initials of team member on all cards.",
        "Feature: Team member Initials & Task ID #  in Task Chat.",
        "Feature: Team member's initials on all places where their names are reflected.",
        "Feature: Contributors do not have access to Change the task assignment to a different team member.",
        "Feature: Modify Task of the Kanban board will have a target date.",
        "Feature: Verify Option for Completed tasks on Tasks.",
        "Bug Fixes: Search Bar in Task module.",
      ]
    },
    {
      id: 55,
      version: "3.0.31",
      date: "10-06-2023",
      highlights: [
        "Feature: Contributor does not have an access to move tasks from backlogs to current sprint.",
        "Feature: Kanban Status has a identifier.",
        "Feature: User Token Expired to 3 days auto log out.",
        "Bug Fixes: Project Report Fixes in admin panel.",
      ]
    },
    {
      id: 54,
      version: "3.0.30",
      date: "10-04-2023",
      highlights: [
        "Bug Fixes: Date Format Issue for Report changed to mm/dd/yyyy.",
        "Bug Fixes: Slider Images Issue Fixed on web properties.",
        "Bug Fixes: Time sheets defects (i.e;start time and end time).",
        "Bug Fixes: Full task information added in priority board cards details.",
        "Bug Fixes: undefined issue for all properties fixed including Landing page."
      ]
    },
    {
      id: 53,
      version: "3.0.29",
      date: "09-29-2023",
      highlights: [
        "Feature: The details of the tasks on Activity board and Kanban Board.",
        "Bug Fixes: Undefined issue side nav.",
        "Bug Fixes: calendar date selection issue.",
        "Bug Fixes: start time, end time fixes at employee reports.",
        "Bug Fixes: Topnav dashboard todo serial numbers based on user story.",
        "Bug Fixes: Fixed Calendar hovering issue on timesheets."
      ]
    },
    {
      id: 52,
      version: "3.0.28",
      date: "09-22-2023",
      highlights: [
        "Feature: Enhanced Employee Reports with Time Tracking",
        "Feature: Converted Task Comments into Table Format.",
        "Feature: Added Timezone Information to Dates.",
        "Bug Fixes: Message Formatting Improvements",
        "Bug Fixes: Support Ticket Assignment to 'Support Tickets EPIC'",
      ]
    },
    {
      id: 51,
      version: "3.0.27",
      date: "09-20-2023",
      highlights: [
        "Feature:  Admin Panel: Reports into tabular format and export option for All Tables.",
        "Feature: Task ID# added to Employee Reports.",
        "Bug Fixes: Fixed Google signup Warning issue.",
        "Bug Fixes: Limit Edit/Delete for restricted Team Squad users.",
        "Bug Fixes: Resolved Priority Board Time Sheet Issues.",
        "Bug Fixes: Enabled Adding Kanban Directly Without requiring a name for the board.",
        "Bug Fixes: Removed the repeat of Date in Active Sprint name Format( Dates shown on hovering)"
      ]
    },
    {
      id: 50,
      version: "3.0.26",
      date: "09-15-2023",
      highlights: [
        "Feature: Task card numbers are changed to Serial numbers.",
        "Bug Fixes: Warning Alert display when 'User already Registered' for signup",
        "Bug Fixes: In To Do, Days count display on Priority Board cards.",
        "Bug Fixes: Employee efficiency bug fixes, Reports are in table format to see the utilization details.",
        "Bug Fixes: Adding comments when creating user story in kanban board.",
        "Bug Fixes: Made label name dynamic  when adding user story to priority board from backlogs."
      ]
    },
    {
      id: 49,
      version: "3.0.25",
      date: "09-13-2023",
      highlights: [
        "Archive: Archive user story displays details of members and story details.",
        "Archive: Blank page of Archive resolved.",
        "Archive: Messages of Archive displays the details.",
        "Reports: Individual workload display issue resolved.",
        "Reports: Employee Reports Added - Utilization Based On Hours, Utilization Based on story Points.",
        "Chat Room: Group creation done the first time a project is created.",
        "Feature: Priority Board Cards display the Priority of Task.",
      ]
    },
    {
      id: 48,
      version: "3.0.24",
      date: "09-06-2023",
      highlights: [
        "Admin can access to make necessary edits to the landing page, such as changing font content, font color, and updating images.",
        "Display the pending count along with individual stories and their respective project names.",
        "Added Search Functionality For Release Notes & FAQs."
      ]
    },
    {
      id: 47,
      version: "3.0.23",
      date: "08-30-2023",
      highlights: [
        "The background color can be changed from the Admin Panel's properties.",
        "User efficiency reports have been updated."
      ]
    },
    {
      id: 46,
      version: "3.0.22",
      date: "08-23-2023",
      highlights: [
        "Bug Fixes on All Users (Including non-domain emails) can access the project.",
        "Bug Fixes on Numbers of hours across the Story points.",
        "Bug Fixes on Undefined Issue.",
        "Bug Fixes on Support and Report Bug Issue."
      ]
    },
    {
      id: 45,
      version: "3.0.21",
      date: "08-11-2023",
      highlights: [
        "Timesheets are updated based on individual sprints within the project.",
        "Generate CSV reports for Admin Reports, Agile Usage Reports, and Project Reports.",
        "A roadblock task can be created when moving a task into the 'Blocked' status. Users can reassign the task to the respective developer."
      ]
    },
    {
      id: 44,
      version: "3.0.20",
      date: "08-02-2023",
      highlights: [
        "Display task/userstory hours spent on backlogs, sprint and priority Board.",
        "User story - activity time.",
        "User should be assign when userstory moves to sprint when user not assigned.",
        "Project reports displayed individual working hours and active working users."
      ]
    },
    {
      id: 43,
      version: "3.0.19",
      date: "07-01-2023",
      highlights: [
        "Accounts creation/Edit from Super Admin.",
        "Display all the Accounts Details.",
        "TaskVelocity Reports.",
        "Subtask Archive added.",
        "Sprint Velocity Changed from BarGraph to Line Graph.",
        "TimeSheets Hours display for Individual stories on Sprint Board, Kanban Board and Backlogs.",
        "Bug fix for timesheets (kanban board story not shown in timesheets).",
        "UI Bug Fixes."
      ]
    },
    {
      id: 42,
      version: "3.0.18",
      date: "04-14-2023",
      highlights: [
        "Support Mail Sent for Reopen the Archive Project.",
        "Bug Fixes."
      ]
    },
    {
      id: 41,
      version: "3.0.17",
      date: "03-14-2023",
      highlights: [
        "Task24x7 Production Release was done with the labels.",
        "Agile24x7 production code change along with Task24x7 linked to the same GIT.",
        "Make Single Backend For Both task and Agile.",
        "Archive Projects has been added so that the owner of the project can archive the project.",
        "Priority Levels added in sprint board.",
        "AGroup details are displayed for Roadblocks chat.",
        "Calendar bugs are resolved."
      ]
    },
    {
      id: 40,
      version: "3.0.16",
      date: "02-02-2023",
      highlights: [
        "Kanban board has Archive and user story differentiation in backlog.",
        "Time zone for chats has been made location specific.",
        "Chat group for the entire project created when a project is created and group members are added to the project.",
        "Archive Project is an additional feature with the ability to reinstate the projects from the Admin panel."
      ]
    },
    {
      id: 39,
      version: "3.0.15",
      date: "01-06-2023",
      highlights: [
        "User stories in To Do shows what project it belongs to and is clickable to take to Active Scrum Board or backlog of that project where the story belongs.",
        "Epic shows the number of active user stories.",
        "Create/Modify the Epic with Target Date.",
        "Kanban Board added to the Side Nav and as a feature independent of Sprint.",
        "Contributor: Number of user stories taken vs completed shown.",
        "Bug Fixes."
      ]
    },
    {
      id: 38,
      version: "3.0.14",
      date: "12-09-2022",
      highlights: [
        "Hide/Un Hide Projects in dashboard - control from Individual user To Do on upper tab.",
        "In Dashboard - Projects display Active User Stories Count and if the project has an Active Sprint.",
        "Active Sprint and number of pending User stories shown at EPIC level.",
        "Remove user story from Sprint back to backlog.",
        "Bug Fixes."
      ]
    },
    {
      id: 37,
      version: "3.0.13",
      date: "11-28-2022",
      highlights: [
        "Active Sprint and number of pending User stories shown at EPIC level.",
        "Bug Fixes."
      ]
    },
    {
      id: 36,
      version: "3.0.12",
      date: "03-18-2022",
      highlights: [
        "Sprint Board or Scrum Board UI is more exposed."
      ]
    },
    {
      id: 35,
      version: "3.0.11",
      date: "03-11-2022",
      highlights: [
        "Calendar event notifications enabled for both web and mobile.",
        "Web notifications enabled for all chats.",
        "Messages display in sorted order in squad chat and chat room.",
        "Team member appreciation through Kudos points enabled for Agile squad."
      ]
    },
    {
      id: 34,
      version: "3.0.10",
      date: "02-25-2022",
      highlights: [
        "Profile shows the type of account (corporate, Free, Paid personal...).",
        "Chat messages show the last message display sorted according to name and time in chat room and personal chat window."
      ]
    },
    {
      id: 33,
      version: "3.0.9",
      date: "02-18-2022",
      highlights: [
        "Working status bar updated to available whenever a person logs into Agile or performs an activity on Active Sprint Board.",
        "Chat response with tag on a particular message in all chat.",
        "Admin level features:",
        "Chat response to a particular message available to Admin."
      ]
    },
    {
      id: 32,
      version: "3.0.8",
      date: "02-11-2022",
      highlights: [
        "Completing task in personal todo and also displaying the completed tasks in personal list.",
        "Working status bar updated along with - Done for the Day - Be right back - Out sick - On Vacation.",
        "Chat response with tag on a particular message in chat room."
      ]
    },
    {
      id: 31,
      version: "3.0.7",
      date: "02-04-2022",
      highlights: [
        "In To Do list only projects you are involved in user stories will be shown.",
        "Projects list in side navigation for header pages like To Do, Chat, Support etc.",
        "User Story template can be modified.",
        "Agile support added on every page, so that you can contact support without logging out.",
        "Agile Squad shows the working status of each user.",
        "Archive search functionality with user story details."
      ]
    },
    {
      id: 30,
      version: "3.0.6",
      date: "01-28-2022",
      highlights: [
        "Admin level features:",
        "Admin panel separation for each Domain and Main Account Holder.",
        "Display of squad list and group chat list sorted according to unread and time.",
        "User level features:",
        "Single sign-on enabled from Google.",
        "Calendar, ToDo, and Chat in every screen on the header part.",
        "Display of squad list and group chat list sorted according to unread and time."
      ]
    },
    {
      id: 29,
      version: "3.0.5",
      date: "01-21-2022",
      highlights: [
        "Admin level features:",
        "FAQ can be added from Admin Panel.",
        "Add, Modify and Delete the FAQ's.",
        "User level features:",
        "Contributor with Limited Access is restricted on all levels.",
        "User story - If a user has a particular user story In Progress then when you move another user story into in progress the system throws a warning to confirm and give details of USER STORY name that is already in In Progress.",
        "Default Target date displayed when user story is moved into Active Sprint from Backlog.",
        "Active Sprint Boards name Changed.",
        "Calendar has all details input from Dashboard."
      ]
    },
    {
      id: 28,
      version: "3.0.4",
      date: "01-13-2022",
      highlights: [
        "User level features:",
        "Personal ToDo List",
        "Modify and Delete the Task from Personal To Do",
        "Convert Personal To Do Task into User Story (Add Task to Agile Project)",
        "Contributor role with limited access added",
        "Calendar",
        "Calendar available on dashboard for all project details",
        "Display user stories and events specific to user",
        "Events can be added with or without selecting project name",
        "FAQ's available from the registration page"
      ]
    },
    {
      id: 27,
      version: "3.0.3",
      date: "01-07-2022",
      highlights: [
        "Admin Panel features:",
        "Usage reports on all verified and unverified users",
        "User registration date and last login date available",
        "User level features:",
        "Consolidated list of To Do from all Projects",
        "A private To Do list can be created by each user",
        "Assigning user to user story is not mandatory",
        "Group chat notifications in the chat room for both admin and user",
        "Under User Dashboard, Individual user chat notifications",
        "Payment Integration for paid accounts or upgrades",
        "Chat collaboration between specific domains improvised",
        "User story has provision to save a template"
      ]
    },
    {
      id: 26,
      version: "3.0.2",
      date: "12-10-2021",
      highlights: [
        "Admin Panel features:",
        "Efficiency of an employee shown in reports",
        "User Registration: Project Count and Project Names Displayed",
        "User level features:",
        "Chat message counts shown through Dashboard",
        "User able to chat with other employees from Different teams through common messaging on Dashboard",
        "By Default User story Target date being taken as end date of the sprint when it is being moved from Backlog to Active Sprint"
      ]
    },
    {
      id: 25,
      version: "3.0.1",
      date: "12-03-2021",
      highlights: [
        "Admin Panel features:",
        "Project reports added to see the number of active projects for each user",
        "Agile Usage report Added to see the number of users registered into the application and the status",
        "Chat message from admin added",
        "User level features:",
        "Chat message from admin to be displayed in the dashboard",
        "Contact page added to support",
        "Report bugs added to support"
      ]
    },
    {
      id: 24,
      version: "3.0.0",
      date: "11-18-2021",
      highlights: [
        "The Agile team is proud to announce the release of Task 24X7 3.0.0. We are very pleased to announce that we have PostgressSQL or Mysql as Database made available from this release",
        "Calendar notifications are redirected to your Calendar",
        "This point release contains many bug fixes and improvements"
      ]
    },
    {
      id: 23,
      version: "2.0.8",
      date: "11-12-2021",
      highlights: [
        "Admin Panel features:",
        "Group Chat added",
        "User level features:",
        "Dashboard added",
        "Delete and Exit from Group",
        "Email verification put as a reminder to the user and access given to the user for temporary use without email verification",
        "Access to the application given with a temporary password",
        "Support option given through email",
        "Each registered user given access to create 10 projects free of cost, and an upgrade option is given"
      ]
    },
    {
      id: 22,
      version: "2.0.7",
      date: "10-22-2021",
      highlights: [
        "Hover on every Icon on every screen",
        "Group Chat Available as Chat Room for Users",
        "Sprint committed vs uncommitted option given",
        "User registration email reminder sent again if the user has not activated the account",
        "UI alignments for Search and other add features"
      ]
    },
    {
      id: 21,
      version: "2.0.6",
      date: "10-14-2021",
      highlights: [
        "Admin Panel features:",
        "Number of user stories an individual is assigned to in each week added to reports in admin",
        "User level features:",
        "Comment box shows all the history of that particular user story",
        "User stories will be moved back to To Do at the end of the day. User will have to move their User story into In Progress once they start the work next day",
        "Chat - Edit images before sending is enabled",
        "Reports- Has Burn down chart added",
        "Sprint can be create and delete option given only by Scrum master or Product Owner"
      ]
    },
    {
      id: 20,
      version: "2.0.5",
      date: "10-07-2021",
      highlights: [
        "User level features:",
        "User - Comment and user story moving to In progress and any message windows displays the user story ID and name",
        "Roadblock- User story in Roadblock shows the ID and user story name",
        "Chat Message - included in archive"
      ]
    },
    {
      id: 19,
      version: "2.0.4",
      date: "10-01-2021",
      highlights: [
        "Admin Panel features:",
        "All Employee reports from Admin Panel (includes the time sheet-total hours)",
        "License key generation for the upgrade of an account",
        "Chat has the preview option of the screenshots put"
      ]
    },
    {
      id: 18,
      version: "2.0.3",
      date: "09-24-2021",
      highlights: [
        "Chat functionality changes",
        "Limit 5 project per user as a Scrum master/product Owner",
        "Auto refresh of Active Sprint screen"
      ]
    },
    {
      id: 17,
      version: "2.0.2",
      date: "09-17-2021",
      highlights: [
        "Admin Panel features:",
        "TimeSheets for every active employee available from Admin panel",
        "Reports are available based on the Days selected from Admin panel",
        "Activate or Deactivate user from Admin and Project level and unassignment of their user stories",
        "User level features:",
        "Calendar has add events and User story deadlines defined",
        "Email Notifications are sent any time there is a change in Calendar event or user story",
        "BurnUp chart added to reports",
        "Mobile Notifications are sent when there is an activating or assignment of the user story, user level and user story level chat"
      ]
    },
    {
      id: 16,
      version: "2.0.1",
      date: "08-13-2021",
      highlights: [
        "User level features:",
        "Activating single user story per user",
        "Calendar email notifications",
        "Profile: Working hours the user added",
        "Show active sprint's user story with different color in calendar",
        "Individual user story sort by user in backlog",
        "Each user's profile with their availability or shift duration"
      ]
    },
    {
      id: 15,
      version: "2.0.0",
      date: "08-07-2021",
      highlights: [
        "Single DB Migration"
      ]
    },
    {
      id: 14,
      version: "1.0.14",
      date: "07-02-2021",
      highlights: [
        "The user Stories are marked as incomplete and given as a not finished on target time",
        "A user story can be assigned smaller Tasks if user wants to subdivide the user stories",
        "Change Status has been modified to 1 click"
      ]
    },
    {
      id: 13,
      version: "1.0.13",
      date: "06-25-2021",
      highlights: [
        "Email notification on all chat modules",
        "Roadblocks can be assigned as a user story and in active sprint the story card will show the ID to the Roadblock User story",
        "Changing user story Status in the backlog manually"
      ]
    },
    {
      id: 12,
      version: "1.0.12",
      date: "06-11-2021",
      highlights: [
        "Mural Board",
        "Chat notifications and uploading file",
        "Add user story / Modify User story Browser compatibility issue -scrollable feature added in model",
        "Backlogs Task Information model size is increased and (1.Active/ de active squad member, 2. Inactive member not showing in squad)",
        "Epic: 1. Do not add empty epic while add/ modify the epic 2. Delete epic whenever epic doesn’t have user stories (whoever added the epic that person able to delete it)",
        "Bugs Fixed: 1. Delete option for Agile Squad member 2. Delete EPIC if it does not have user stories"
      ]
    },
    {
      id: 11,
      version: "1.0.11",
      date: "05-28-2021",
      highlights: [
        "In Chat files and images upload/download",
        "Loaders added while assign, modify and reassign user story",
        "Bugs Fixed"
      ]
    },
    {
      id: 10,
      version: "1.0.10",
      date: "05-21-2021",
      highlights: [
        "Support page with version release notes",
        "Sprint tab has removed from epics",
        "In squad members dropdown list has been updated with all squad members",
        "Bugs Fixed"
      ]
    },
    {
      id: 9,
      version: "1.0.9",
      date: "05-07-2021",
      highlights: [
        "Backlog board with Drag and Drop",
        "Reports for individual Workload",
        "Reassign the Completed User Stories from Backlogs and Epic Level",
        "Chat Bug solved in Active Sprint",
        "Bugs Solved"
      ]
    },
    {
      id: 1,
      version: "1.0.8",
      date: "04-27-2021",
      highlights: [
        "Modify Sprint in Backlogs",
        "Reports"
      ]
    },
    {
      id: 2,
      version: "1.0.7",
      date: "04-23-2020",
      highlights: [
        "Agile Secure version with CA.",
        "Epic completion without dependency of user story creation.",
        "Adding a key for epic and project.",
        "Sprint cycle time change to 11.59pm PST on the end date of Sprint.",
        "Date format changed in archive to mm/dd/yy.",
        "In blocked list, show only roadblocks of the current sprint (present sprint).",
        "In comment box, the last message of comment box to be shown first.",
        "Removed mobile validation in agile squad while add/modify squad member.",
        "Check if all the user stories actions are done by user in sprint board, sprint board cannot switch."
      ]
    },
    {
      id: 3,
      version: "1.0.6",
      date: "04-16-2021",
      highlights: [
        "No Epic Dependency.",
        "Search Bar added to backlog, and Archive.",
        "Alphabetic order for Agile project Names.",
        "Backlog part in two cases i.e. with epic and without epic.",
        "Roadblocks on current sprint board.",
        "In chatbox date format has changed.",
        "Activate and deactivate Squad member.",
        "Comment box changes.",
        "Date for Version has changed.",
        "Completion of epic.",
        "Add validations.",
        "Block list should be displayed on scrum board.",
        "Give an option to assign  while adding userstory."
      ]
    },
    {
      id: 4,
      version: "1.0.5",
      date: "04-09-2021",
      highlights: [
        "Forgot Password",
        "Landing page",
        "Registration page",
        "Backlog Board with active and feature sprints user stories and backlogs",
        "Reset Password",
        "New Squad member to be added can be picked from existing data",
        "Sprint card details",
        "Roles Displayed with Squad Name in the chatbox"
      ]
    },
    {
      id: 5,
      version: "1.0.4",
      date: "03-26-2021",
      highlights: [
        "Active sprint board",
        "Backlog Board",
        "Archive Reports",
        "Removed Dashboard",
        "Display Release Date for every version/release",
        "Sprint should have a display of Current Active Sprint user-stories"
      ]
    },
    {
      id: 6,
      version: "1.0.3",
      date: "03-12-2021",
      highlights: [
        "Switching between the projects from inside the application.",
        "Add project after login.",
        "Remove the corp code and user type selection while login.",
        "Email notification while registered or add squad and add new project."
      ]
    },
    {
      id: 7,
      version: "1.0.2",
      date: '',
      highlights: [
        "Removed admin role from application and added three roles(i.e. scrum master, contributor, product owner).",
        "User stories page and Backlogs page are merged into a single page as backlogs."
      ]
    },
    {
      id: 8,
      version: "1.0.1",
      date: '',
      highlights: [
        "PTMS core version with properties file modification for Agile"
      ]
    }
  ];
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredNotes, setFilteredNotes] = useState(releaseNotes);
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const handleSearchInputChange = e => {
    setSearchQuery(e.target.value);
    setLoading(true);
   // const query = e.target.value.toLowerCase();
    // setSearchQuery(query);

    // const filteredResults = releaseNotes.filter(note =>
    //   note.highlights.some(highlight => highlight.toLowerCase().includes(query)) || note.date.includes(searchQuery) || note.version.includes(searchQuery)
    // );
    //console.log('Fil:',filteredResults);

    //setFilteredNotes(filteredResults);
  };

 // Filter notes whenever the debounced search query changes
 useEffect(() => {
  
  if (debouncedSearchQuery) {
    const lowercasedQuery = debouncedSearchQuery.toLowerCase();
    const results = releaseNotes.filter((note) =>
      note.version.toLowerCase().includes(lowercasedQuery) ||
      note.date.toLowerCase().includes(lowercasedQuery) ||
      note.highlights.some((highlight) =>
        highlight.toLowerCase().includes(lowercasedQuery)
      )
    );
    setFilteredNotes(results);
  } else {
    setFilteredNotes(releaseNotes);
  }
  setLoading(false);
   // eslint-disable-next-line
}, [debouncedSearchQuery]);
  return (
    <Layout pageName='release'>
      <div>
        <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
          <button style={{ background: 'white' }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            {/* eslint-disable-next-line */}
            <a className="navbar-brand"><Link to={{ pathname: "/" }}>
              {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
              <img className="agile-supportlogo" src={properties?.IMG_SRC || IMG_SRC} width="170" alt="" /></Link>

            </a>
            <form className="form-inline my-2 my-lg-0 ml-auto" style={{ paddingTop: "16px" }}>
              {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
              {/* eslint-disable-next-line */}
              <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit" ><Link style={{ color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} to={{ pathname: "/login" }}>Login</Link></a>
              {/* eslint-disable-next-line */}
              <a className="btn my-2 my-sm-0" id="signupbtn-support" type="submit" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}><Link style={{ color: 'white' }} to={{ pathname: "/" }}>Home </Link></a>
              {/* support page button */}
            </form>
          </div>
        </nav>

        <section id="section1">
          <div className="container">
            <div className="release-note mt-3">
              <div className="d-flex bd-highlight"> <div className="p-2 flex-grow-1 bd-highlight text-center"><h2>{webProperties.APP_NAME || APP_NAME} release notes</h2></div>  </div>
            </div>
          </div>
        </section>

        {/* collapse view */}
        <div className="container mt-2 ">
          <div className='d-flex justify-content-end'> <input className='form-control bt-n' type="search" placeholder="Search... highlights,date,version" value={searchQuery} onChange={handleSearchInputChange} style={{ width: '30%' }} /></div>
          <div className="accordion mt-3" id="accordionExample">
            { loading ? ( <RootLoader/> ) :
            (<>
               {filteredNotes.length > 0 ? (
                  filteredNotes.map(note => (
                    <div className="card" key={note.id}>
                      <div className="card-header" id={`heading${note.id}`}>
                        <h2 className="mb-0">
                          <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target={`#collapse${note.id}`} aria-expanded="true" aria-controls={`collapse${note.id}`} >
                            <h5 className="text-primary">{`${webProperties.APP_NAME || APP_NAME} version ${note.version} release notes`}</h5>
                          </button>
                          <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target={`#collapse${note.id}`} aria-expanded="true" aria-controls={`collapse${note.id}`}  >
                            <h5 className="text-primary">{`Date : ${note.date}`}</h5>
                          </button>
                        </h2>
                      </div>
                      <div id={`collapse${note.id}`} className="collapse" aria-labelledby={`heading${note.id}`} data-parent="#accordionExample" >
                        <div className="card-body">
                          <div className="releasenote-cardbody">
                            <div className="card-title text-capitalize mt-3 mb-3">
                              <h6>Highlights</h6>
                            </div>
                            <div className="list">
                              <ul>
                                {note.highlights.map((highlight, index) => (
                                  <li key={index} className="ml-1">
                                    {highlight}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
               ) 
                : (
                  <p>No matching release notes found.</p>
                )
              }
                </>)
            }          
          </div>
        </div>


        {/* End collapse view */}



        <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
          <div style={{ color: 'white' }}>
            {webProperties?.APP_NAME || APP_NAME} © Copyright {new Date().getFullYear()}. All Rights Reserved.
          </div>
        </footer>
      </div>
    </Layout>

  )
}
